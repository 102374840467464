import React from 'react';

import Row from '../Container/row';
import Col from '../Container/column';
import PageSubtitle from '../Subpage/subtitle';

import { MainDark } from '../../utils/variables';

import './style.css';

import loadComponents from '../Loadable';

const H3 = loadComponents('h3');
const H4 = loadComponents('h4');

export default function TestimonialBlock({ title, data }) {
  return (
    <div className="subpageComponent testimonials">
      {title && <PageSubtitle title={title} />}
      <Row justify="space-between">
        {data.map((item, index) => {
          const key = `testimonial${index}`;
          return (
            <Col key={key} className="item" xl={{ width: '30%' }}>
              <div>
                <p>{item.content}</p>
                <H3 size="28px" margin="10px">
                  {item.name}
                </H3>
                <H4 size="20px" margin="0px" colour={MainDark}>
                  {item.jobTitle}
                </H4>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
